#product-page{
  .product-page-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 884px;
  }
  .box-nav{
    // padding:25px 22px 0;
    width:244px;
    padding-top: 25px;
    background-color:#f9f9f9;
    // display: flex;
    // flex-direction: row
    // justify-content: ;
  }
  .nav-title{
    margin: 0 auto;
    font-size:20px;
    color:#014c6b;
    line-height:26px;
    text-align: center;
    width: 200px;
    .sub-title{
      font-size:12px;
      line-height:26px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      span{
        width: 32px;
        height: 2px;
        background-color: #014c6b;
      }
    }
  }
  .nav-wrapper{
    margin-top: 20px;
    
    li{
      // padding: 15px 40px;
      cursor: pointer;
      margin: 0 auto;
      width: 200px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background-color: #cccccc;
      color:#666666;
      margin-bottom: 15px;
      &.active{
        background-color: #014c6b;
        color: #ffffff;
      }
    }
  }
  .box-content{
    padding:25px 26px 0;
    text-align: left;
    width: 940px;
    box-sizing: border-box;
    background-color:#f9f9f9;
    font-size:14px;
    color:rgba(150,150,150,1);
    line-height:34px;
    .content-title{
      text-align: center;
      padding: 0 25px 19px;
      font-size:16px;
      line-height:24px;
      border-bottom: 1px solid #E4E4E4;
    }
    .content-wrapper{
      // padding:25px 0 0 35px;
      width: 852px;
      margin-left: 35px;
      padding: 25px 0 170px;
    }
  }
}
#header{
  width:100%;
  padding:18px 0 10px;
  .container{
    width:62.5%;
    margin:0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height:49px;
    position: relative;
  }
  .logo{
    width:238px;
    height:49px;
    img{
      width:238px;
      height:49px;
    }
  }
  .hd-nav_box{
    position: relative;
    // height: 50px;
    .mousewith{
      position: absolute;
      width:34px;
      height:6px;
      background: #333333;
      left:0;
      bottom:-16px;
      transition:left 300ms;
    }
  }
  .nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    font-size: 18px;
    li{
      padding:0 32px;
      a{
        color: #333333;
      }
      &:nth-child(1){
        padding-left:0
      }
      &:last-child{
        padding-right:0
      }
    }
  }
  .lang_switcher{
    position: absolute;
    top:0;
    right:-15%;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 18px;
    color: #333333;
    cursor: pointer;
  }
  .lang_icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url(~@assets/img/chinese.png);
    background-size: contain;
  }
}
#service-page{
  .service-box{
    width:100%;
    background: #f9f9f9;
    position: relative;
    z-index: 1;
    section{
      padding-top:30px;
      background: #f9f9f9;
      padding-bottom: 55px;
    }
    .service-title{
      text-align: center;
      font-size: 20px;
      line-height: 26px;
      color:#3f6e90;
    }
    .sub-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 200px;
      margin: 0 auto;
      font-size:12px;
      line-height: 26px;
      .line{
        width: 32px;
        height: 2px;
        background-color: #3f6e90;
      }
    }
    .service-swiper{
      width: 1020px;
      height: 270px;
      margin: 20px auto 70px;
      position: relative;
    }
    .service-slide{
      position: absolute;
      width: 22px;
      height: 40px;
      top:90px
    }
    .service-prev{
      background-image: url(~@img/service-prev.png);
      left:-47px;
    }
    .service-next{
      background-image: url(~@img/service-next.png);
      right: -47px;
    }
    .service-item{
      width:320px;
      // height: 270px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      .item-img-box{
        width:320px;
        height: 220px;
        margin-bottom: 7px;
      }
      img{
        width:300px;
        height: 200px;
        margin:10px;
      }
      p{
        font-size:16px;
        font-weight:400;
        color: #666666;
        line-height:24px;
      }
  
      &.active,&:hover{
        .item-img-box{
          background-color: #7D9CB3;
        }
        p{
          color: #3F6E90;
        }
      }
    }
    .service-content{
      width: 1082px;
      margin:0 auto;
      color: #666666;
      .content-title{
        font-size:16px;
        line-height:24px;
        border-bottom: 1px solid #E4E4E4;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }
      font-size:14px;
      line-height:24px;
      text-align: center;
    }
    .extra-box{
      display: flex;
      flex-direction: row;
      width: 1000px;
      margin: 30px auto 0;
      justify-content: space-between;
      img{
        width: 320px;
        height: 180px;
      }
      .extra-item{
        background-color: #ffffff;
        width: 320px;
        text-align: center;
        padding-bottom: 29px;
      }
      .extra-title{
        font-size:16px;
        color:rgba(51,51,51,1);
        line-height:26px;
        padding-bottom: 5px;
        padding-top: 13px;
      }
      .extra-content{
        font-size:14px;
        color:rgba(102,102,102,1);
        line-height:26px;
        text-align: left;
        padding-left:23px;
        padding-right: 30px;
      }
    }
  }
}

.news-wrapper{
  width: 100%;
  background-color: #f9f9f9;
  padding:23px 80px 65px;
  position: relative;
  z-index: 1;
  color: #666666;
  .news-breadcrumbs{
    font-size:16px;
    color:rgba(51,51,51,1);
    line-height:24px;
    padding-bottom:12px;
    border-bottom: 1px solid  #DBDBDB;
  }
  .news-title{
    text-align: center;
    .title{
      font-size:20px;
      color:rgba(51,51,51,1);
      line-height:24px;
      margin-top: 32px;
    }
    .author{
      color: #8F8F8F;
      font-size: 16px;
      line-height:24px;
      color:#8F8F8F;
      padding:13px 0 17px;
    }
  }
  .news-content{
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    img{
      max-width: 100%;
      height: auto;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}
// index banner
#banner{
  width:100%;
  height:600px;
  background: #333333;
  overflow: hidden;
  img{
    // height: 100%;\
    // width: 100%;
  }
  .banner-img{
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .swiper-pagination-bullet{
    width:30px;
    height:5px;
    background:rgba(255,255,255,1);
    opacity:0.5;
    &.swiper-pagination-bullet-active{
      background:rgba(140,192,225,1);
    }
    border-radius:0
  }
}

.banner-item{
  position: relative;
  .banner-desc{
    position: absolute;
    right:18.75%;
    top:226px;
    h2{
      font-size:60px;
      font-family:Lantinghei SC;
      font-weight:600;
      color:rgba(255,255,255,1);
      margin-bottom: 25px;
    }
    h5{
      font-size:18px;
      font-family:MicrosoftYaHei;
      font-weight:bold;
      color:rgba(255,255,255,1);
      margin-bottom: 43px;

    }
    .banner-btn{
      width: 100%;
      a{
        display: block;
        color:rgba(84,175,189,1);
        font-size:24px;
        font-family:Arial Black;
        font-weight:400;
        width: 230px;
        padding:16px 41px;
        box-sizing: border-box;
        border:2px solid #54AEBD;
        margin:0 auto;
        &:hover{
          background-color: #54AEBD;
          color: #ffffff;
        }
      }
      
    }
  }
}

// index title
.index-title-main{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size:24px;
  font-family:Yuanti SC;
  font-weight:400;
  color:rgba(51,51,51,1);
  line-height:26px;
}
.index-title-line{
  width: 18px;
  height:2px;
  display: inline-block;
  background-color:#A43B40;
  margin-right: 12px;
}
.index-title-sub{
  font-size:14px;
  font-family:Yuanti SC;
  font-weight:400;
  color:rgba(102,102,102,1);
  line-height:26px;
  padding-left:30px;
}

// index product
#index-product{
  padding-bottom: 55px;
  .container{
    display: flex;
    flex-direction: row;
  }
  .top-triangle{
    width: 0;
    height: 0;
    border-width: 150px;
    border-style: solid;
    border-color: #ebebeb transparent transparent transparent;
    margin-left:32px;
  }
  .index-title{
    margin-top: -80px;
    margin-left: 40px;
  }
  .product-desc{
    font-size:18px;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:40px;
    margin-top: 11px;
    margin-left: 40px;
    position: relative;
    p{
      margin-left: 22px;
      cursor: pointer;
    }
    .desc-title-active{
      color:#666666;
    }
    .active-line{
      position: absolute;
      height: 14px;
      width: 2px;
      left:0;
      top:13px;
      background-color:#4BC9C1;
      transition: all 300ms;
    }
  }
  .index-product_right{
    display: flex;
    flex-direction: row;
    padding-top:82px;
    position: relative;
    padding-left:26px;
  }
  .product-img{
    width:431px;
    height:393px;
    background:rgba(235,235,235,1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    img{
      width:398px;
      height:363px;
    }
  }
  .product-box{
    width:426px;
    height:252px;
    box-sizing: border-box;
    padding:26px 100px 15px 26px;
    border:1px solid #1a1a1a;
    background: #ffffff;
    position: absolute;
    bottom:0;
    left:356px;
    ul{
      font-size:16px;
      margin-top: 20px;
      font-weight:400;
      color:rgba(102,102,102,1);
      line-height:28px;
      li:before{
        content:'▶';
        color:#a40013;
        margin-right:10px;
      }
    }
  }
  .box-title{
    color:#2A6F93;
    line-height: 24px;
    p{
      &:nth-child(1){
        font-size: 26px;
      }
      &:nth-child(2){
        font-size: 16px;
      }
    }
  }
  .product-more{
    cursor: pointer;
    font-size:14px;
    font-weight:400;
    line-height:24px;
    position: absolute;
    right:0;
    bottom:-34px;
    text-align: right;
    a{
      color: #333333;
    }
    span{
      display: block;
      width:51px;
      height:5px;
      background:url(~@img/more-line.png);
      background-repeat: no-repeat;
      background-position-x:0;
    }
    &:hover{
     a{
      color: #666666;
     }
      span{
        animation:hoverMove 1s infinite;
        -webkit-animation:hoverMove 1s infinite; /* Safari 和 Chrome */
      }
    }
  }
  
}
@keyframes hoverMove{
  0% {background-position-x:0px;}
  50% {background-position-x:51px;}
  100% {background-position-x:0px;}

}
@-webkit-keyframes hoverMove{
  0% {background-position-x:0px;}
  50% {background-position-x:51px;}
  100% {background-position-x:0px;}
}
// index bussiness
#index-bussiness{
  width:100%;
  height: 500px;
  background-color: #EBEBEB;
  box-sizing: border-box;
  padding-top: 70px;
  position: relative;
  .index-title{
    margin-left: 40px;
  }
  .bussiness-line{
    width: 100%;
    height:1px;
    background:rgba(208,208,208,1);
    position: absolute;
    top:295px;
    z-index:0;
  }
  
  .outer-box{
    width:1110px;
    margin:70px auto 0;
  }
  .bussiness-box{
    padding-bottom: 30px;
    width:1110px;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
  }
  .bussiness-box-item{
    width:180px;
    height:227px;
    position: relative;
    cursor: pointer;
    &:nth-child(3){
      p{
        width: 180px;
      }
    }
    &:nth-child(4){
      p{
        width: 180px;
      }
    }
    p{
      width: 128px;
      text-align: center;
      margin: 0 auto;
      color:#8C8C8C;
      font-size: 16px;
      line-height: 26px;
      &:nth-child(1){
        color:#666666;
        font-size: 20px;
        line-height: 26px;
        margin-top: 33px;
      }
    }
    .item-line{
      width: 100%;
      background-color: #EBEBEB;
      height:13px;
      margin:38px 0 26px;
      position: relative;
      z-index:11;
      display: flex;
      flex-direction: row;
      justify-content:space-between;
    }
    .item-line-fill{
      width: 71px;
      height: 1px;
      background-color:#d0d0d0;
      margin-top: 6px;
    }
    .item-line-icon{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span{
        width:7px;
        background-color:#EBEBEB;
        height:100%;
      }
      .lines{
        width: 7px;
        height: 1px;
        background-color: #d0d0d0;
        border-radius:0;
      }
      .circle{
        width:8px;
        height: 8px;
        border-radius:50%;
        background-color: #d0d0d0;
        box-sizing: border-box;
      }
    }
    &:hover{
      background:#F2F2F2;
      box-shadow:0px 8px 20px 0px rgba(79,161,155,0.3);
      p{
        color:#3F6E90;
      }
      .item-line{
        background-color: rgba(242,242,242,1);
      }
      span{
        width:7px;
        height:7px;
        border-radius: 50%;
        background-color: #97c5c1;
      }
      .circle{
        background-color: #ffffff;
        box-shadow:0px 0px 6px 1px #97c5c1;
      }
    }
  }
  // .swiper-container{
  //   padding:20px 0;
  //   z-index:11
  // }
  .moving-cursor{
    position: absolute;
    display: block;
    width:35px;
    height: 22px;
    background-image: url(~@img/moving-cursor.png);
    left:50%;
    top: 284px;
    z-index: 12;
  }
}

// index service
#index-service{
  box-sizing: border-box;
  padding-top: 70px;
  padding-bottom: 155px;
  .service-title{
    width:100%;
    padding-left:40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .service-more{
    cursor: pointer;
    a{
      color: #333333;
      &:hover{
        color: #8C8C8C;
      }
    }
  }
  .service-box{
    width:950px;
    height:331px;
    border:10px solid rgba(246,246,246,1);
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top:60px;
    position: relative;
  }
  .service-box-wrapper{
    position: absolute;
    width:1109px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top:-22px;
    left:50px;
  }
  .service-item{
    cursor: pointer;
    width:354px;
    position: relative;
    img{
      width:354px;
      height:227px;
      background:rgba(227,227,227,1);
      margin-bottom:20px
    }
    p{
      width:334px;
      margin: 0 auto;
      text-align: center;
      font-size:16px;
      font-weight:400;
      color:rgba(102,102,102,1);
      line-height:26px;
      .item-title{
        font-size:20px;
        font-family:Yuanti SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:26px;
        margin-bottom: 9px;
      }
    }
    &:hover{
      .service-item-content{
        display:block;
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        animation-duration: 600ms;

      }
    }
  }
  .service-item-content{
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
    animation-duration: 300ms;
    position: absolute;
    left:0;
    right:0;
    width:355px;
    height:412px;
    background:rgba(26,26,26,1);
    box-shadow:0px 8px 20px 0px rgba(91,189,182,0.3);
    opacity:0.9;
    z-index: 100;
    box-sizing: border-box;
    padding:70px 41px 60px;
    color: #54AFBD;
    text-align: center;
    font-size:16px;
    line-height:26px;
    p{
      color: #54AFBD;
      text-align: center;
      font-size:16px;
      line-height:26px;
      width: 100%;
    }
    .title{
      font-size:26px;
      margin-bottom: 40px;
    }
    a{
      display:block;
      line-height: 30px;
      color: #54AFBD;
      text-align: center;
    }
    span{
      margin-top: 50px;
      display: inline-block;
      width:123px;
      height:30px;
      border:1px solid rgba(84,175,189,1);
      border-radius:15px;
      &:hover{
        background-color: rgba(84,175,189,1);
        color:#ffffff;
      }
    }
  }
}
// index partner
#index-partner{
  padding-bottom:40px;
  ul{
    width: 100%;
    display: flex;
    margin-bottom:18px;
    flex-direction: row;
    justify-content: space-around;
    li{
      width:280px;
      height:100px;
      background:rgba(255,255,255,1);
      border:1px solid rgba(216, 216, 216, 1);
      display: flex;
      align-items: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      filter:brightness(98%);
      img{
        width:100%;
      }
      &:hover{
        filter: brightness(100%);
      }
    }
  }
}
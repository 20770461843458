#about-page{
  .about-box{
    width:100%;
    height: 1050px;
   
    display: flex;
    flex-direction: row;
  }
  .about-nav{
    width: 193px;
    padding-top:34px;
    background-color: #EAEAEA;
    font-size:16px;
    text-align: center;
    line-height:50px;
    position: relative;
    li{
      position: relative;
      z-index: 2;
      cursor: pointer;
      color: #333333;
      transition: all 200ms;
    }
    .active{ 
      color: #ffffff;
    }
    .active-decorator{
      position: absolute;
      left:12px;
      top:40px;
      width:195px;
      height:47px;
      background: url(~@img/active-decorator.png);
      z-index:1;
      transition: all 200ms;
    }
  }
  .about-content{
    width: 950px;
    padding-top:34px;
    padding-left:57px;
    background-color: #F9F9F9;
    font-size:14px;
    color:#666666;
    line-height:28px;
    section{
      width: 892px;
      padding-bottom: 50px;
    }
  }
  .culture{
    span{
      font-weight: bold;
    }
    .idea-item{
      display: flex;
      flex-direction: row;
      p{
        width: 290px;
      }
    }
  }
  .history-step{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .step-line{
      position: absolute;
      width: 2px;
      height: 250px;
      background-color: #EAEAEA;
      top:0;
      left:50%;
    }
    .history-item{
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 60px;
      &.right-item{
        justify-content: flex-end;
        text-align: left;
        div{
          width:50%;
          position: relative;
          box-sizing: border-box;
        }
        p{
          padding-left:25px;
        }
        .cirle-decorator{
          left:-6px;
        }
      }
      &.left-item{
        justify-content: flex-start;
        text-align: right;
        div{
          width:50%;
          // background-color: #a40013;
          position: relative;
          box-sizing: border-box;
        }
        p{
          padding-right:25px;
        }
        .cirle-decorator{
          right:-8px;
        }
      }
      .time{
        color:#3F6E90;
        font-size:24px;
        font-weight:bold;
        line-height:28px;
      }
      
    }
    .cirle-decorator{
      border-radius: 50%;
      background: #EAEAEA;
      position: absolute;
      width: 14px;
      height: 14px;
    }
  }
  
  .content-title{
    font-size:18px;
    color:#333333;
    line-height:28px;
    margin-bottom: 14px;
    &:before{
      content:'▶';
      color:#a40013;
      margin-right:10px;
    }
  }
  .nember-swiper{
    width: 813px;
    margin:16px auto 0;
    position: relative;
    .member-item{
      width: 257px;
      height: 368px;
      margin-top: 50px;
      background-image: url(~@img/nember-bg.png);
      position: relative;
      font-size:14px;
      color:#666666;
      line-height:24px;
      // padding:0 16px;
      .member-avatar{
        width: 100px;
        height: 100px;
        background-color: #dedede;
        margin: -50px auto 0;
        overflow: hidden;
        border-radius:50%;
        img{
          width: 120%;
          height: 120%;
          transform: translate(-10px,-1px);
        }
      }
      .member-name{
        color: #3F6E90;
        font-size:20px;
        line-height: 24px;
        text-align: center;
        margin: 19px 0 15px;
      }
      p{
        padding:0 16px
      }
    }
    .swiper-item-next,.swiper-item-prev{
      position: absolute;
      top:208px;
      width: 10px;
      height: 18px;
      background-size: contain;
      z-index: 1;
      cursor: pointer;
    }
    .swiper-item-prev{
      left:-20px;
      background-image: url(~@img/prev.png);
    }
    .swiper-item-next{
      right:-20px;
      background-image: url(~@img/next.png);

    }
  }
  .connect-title{
    display: flex;
    flex-direction: row;
    height: 95px;
    position: relative;
    
    .title-line{
      background-image: url(~@img/title-line.png);
      background-size: contain;
      position: absolute;
      top:-19px;
      left:82px;
      width: 56px;
      height: 95px;
      z-index: 0;
    }
    p{
      color:#3F6E90;
      font-size:50px;
      line-height: 50px;
      height: 50px;
      font-family:Lantinghei SC;
      font-weight:600;
      z-index: 1;
      position: relative;
      background-color: #F9F9F9
    }
    div{
      p{
        font-size:18px;
        font-weight:400;
        color:#333333;
        line-height:20px;
        height: 20px;
      }
    }
  }
  .connect-info{
    font-size:14px;
    color: #5E5E5E;
    line-height:26px;
    div{
      display: flex;
      p{
        &:nth-child(1){
          width: 195px;
        }
      }
    }
  }
  .about-qrcode{
    margin-top: 37px;
  }
  .connect-one{
    width: 897px;
    padding-bottom: 47px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    justify-content: row;
    justify-content: space-around;
  }
  .connect-two{
    padding-top: 28px;
    .part-two-title{
      color:#333333;
      font-size:18px;
      line-height:24px;
      &:before{
        content:'▶';
        color:#a40013;
        margin-right:10px;
      }
    }
    form{
      width:424px;
      margin:41px auto 0;
      .form-item{
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        label{
          width:111px;
          height:37px;
          color: #ffffff;
          background-color: #3F6E90;
          font-size:14px;
          text-align: center;
          line-height: 37px;
        }
        input{
          width:301px;
          height:37px;
          border:1px solid #D8D8D8;
          box-sizing: border-box;
          text-indent: 2em;
        }
        input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color:   #A3A3A3
       }
       input::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color:  #A3A3A3
       }
       input:-moz-placeholder { /* Mozilla Firefox 19+ */
          color:   #A3A3A3
       }
       input:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color:   #A3A3A3
       }
      }
    }
    .message-board{
      height: 107px;
      label{
        height: 107px !important;
        line-height: 107px !important;
      }
      textarea{
        width: 301px;
        height: 107px;
        border:1px solid #D8D8D8;
        box-sizing: border-box;
        text-indent: 2em;
      }
      textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:   #A3A3A3
     }
     textarea::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:  #A3A3A3
     }
     textarea:-moz-placeholder { /* Mozilla Firefox 19+ */
        color:   #A3A3A3
     }
     textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color:   #A3A3A3
     }
    }
    .tips{
      color: #FF0000;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
    }
    .form-submit-btn{
      width:111px !important;
      height:37px !important;
      background: #164668;
      text-align: center !important;
      line-height: 37px !important;
      margin-top: 17px;
      text-align: center !important;
      text-indent: 0em !important; 
      color:#fff;
      &:hover{
        background-color: #3F6E90;
      }
    }
  }
}

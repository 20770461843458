/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    body,html{
      width: 1300px;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  body,html{
    width: 1300px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  body,html{
    width: 1300px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  body,html{
    width: 1300px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  body,html{
    width: 100%;
  }
}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  body,html{
    width: 100%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  body,html{
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  body,html{
    width: 100%;
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
  body,html{
    width: 100%;
  }
}
@import './header.scss';
@import './footer.scss';
@import './sider.scss';

body,html{
  width:100%;
  height:100%;
  background:#fff;
}

.container{
  width:1200px;
  margin:0 auto;
}
.single-banner{
  width: 100%;
  overflow: hidden;
}
.other-page{
  margin-top: -108px;
  padding-bottom: 108px;
}

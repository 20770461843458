.dynamic-box{
  width: 100%;
  margin-top:46px;
  .dynamic-nav{
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 60px;
    justify-content: flex-start;
    li{
      width: 200px;
      text-align: center;
      margin-right: 10px;
      background-color:#3F6E90;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      &.active{
        font-size: 20px;
        background-color: #F9F9F9;
        color:rgba(51,51,51,1);
      }
    }
  }
  .dynamic-content{
    width: 100%;
    padding:33px 66px 84px;
    box-sizing: border-box;
    background-color: #F9F9F9;
    a{
      display: block;
      &:hover{
        filter: brightness(110%);
      }
    }
    .dynamic-item{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 38px;
      cursor: pointer;
      
    }
    .item-img{
      width: 154px;
      height: 154px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        background-color: #dedede;
      }
      .img-border{
        width: 154px;
        height: 154px;
        border:2px solid #3F6E90;
        position: absolute;
        top:-10px;
        left: -11px;
        box-sizing: border-box;
      }
    }
    .item-detail{
      width: 878px;
      padding-bottom: 15px;
      border-bottom: 1px solid #DBDBDB;
    }
    .item-title{
      font-size:16px;
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:24px;
    }
    .item-abstract{
      font-size:14px;
      font-weight:400;
      color:rgba(102,102,102,1);
      line-height:24px;
      padding:8px 0;
    }
    .item-sub{
      font-size:14px;
      font-weight:400;
      color:rgba(63,110,144,1);
      line-height:24px;
      display: flex;
      justify-content: space-between;
    }
  }
  .pagination-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ul{
      display: flex;
      flex-direction: row;
    }
    li{
      height: 30px;
      line-height: 30px;
      padding:0 6px 0 9px;
      border: 1px solid #C4C4C4;
      font-size:14px;
      color:rgba(102,102,102,1);
      margin-left: 14px;
      cursor: pointer;
      &.page-prev,&.page-next{
        padding:0 6px;
      }
      &.active{
        border-color: #3F6E90;
        color: #3F6E90;
      }
      &.disabled{
        color:#C4C4C4;
      }
    }
  }
}